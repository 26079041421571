import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { isFutureSet } from "@/game-tft/constants.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import UnitToolTip from "@/game-tft/UnitToolTip.jsx";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { getLocale, getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const columns = [
  {
    label: ["common:navigation.champion", "Champion"],
    dataKey: "champion",
    align: "left",
    primary: true,
  },
  {
    label: ["lol:tier", "Tier"],
    dataKey: "tier",
  },
  {
    label: ["tft:pickRate", "Pick Rate"],
    dataKey: "pick_rate",
  },
  {
    label: ["common:avgPlace", "Avg. Place"],
    dataKey: "avg_placement",
  },
  {
    label: ["tft:topFour", "Top 4"],
    dataKey: "top_4_percent",
  },
];

export default function HomeStatisticsTile() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const state = useSnapshot(readState);
  const set = useSetByParam(true);
  const unitFilters = getFilters(searchParams, "champions", isFutureSet(set));
  const champions = state.tft[set]?.champions;
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const localizedChampions = useLocalizedChampions(set);

  const unitStats = useMemo(() => {
    const data = state.tft.stats.units?.[unitFilters]?.[set];
    if (data && Object.keys(data)?.length && !(data instanceof Error)) {
      const result = Object.keys(data).map((key) => {
        const champStats = data[key];
        const champData = champions[key];
        return {
          key: key,
          href: `/tft/champion/${key}?set=${set.replace("set", "")}`,
          ...champStats,
          name: localizedChampions?.[key]?.name ?? champData?.name,
        };
      });

      return result.sort((a, b) => b.pick_rate - a.pick_rate);
    }
    return typeof data !== "object" || data instanceof Error ? [] : data;
  }, [state.tft.stats.units, unitFilters, set, champions, localizedChampions]);

  const patch = state.tft.latestPatch?.patch;

  const cols = columns.map((col) => {
    return {
      display: t(col.label[0], col.label[1]),
      align: col.align,
      primary: col.primary,
      isStat: col.isStat,
    };
  });
  const rows = unitStats.map((item) => {
    return columns.map((col) => {
      return {
        display: colRenderer({
          dataKey: col.dataKey,
          rowData: item,
          set: set,
          t: t,
          champion: champions[item.key],
          isSkinsEnabled: isSkinsEnabled,
        }),
        value: item[col.dataKey],
        className: col.className,
      };
    });
  });

  return (
    <GameGuideTileLg
      title={["tft:champions", "Champions"]}
      description={[
        "home:guides.tft.champions.description",
        "Stay ahead of the TFT meta with Blitz.gg. Explore the top Champions in the meta.",
      ]}
      buttonText={[
        "common:navigation.viewChampionStatistics",
        "View Champion Stats",
      ]}
      href={"/tft/champions/stats"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="main-col">
            {patch
              ? t("common:patchNumber", "Patch {{patchNumber}}", {
                  patchNumber: patch,
                })
              : cols[0].display}
          </div>
          <div className="stat">{cols[1].display}</div>
          <div className="stat">{cols[2].display}</div>
          <div className="stat">{cols[3].display}</div>
          <div className="stat">{cols[4].display}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body numRows={6}>
          {(rows || []).map((row, i) => {
            return (
              <GameGuideTable.Row key={i} className={augmentRows()}>
                <div className="main-col">{rows[i][0].display}</div>
                {rows[i].slice(1).map((item, y) => (
                  <div className="stat" key={i + y}>
                    {item.display}
                  </div>
                ))}
              </GameGuideTable.Row>
            );
          })}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

// Renderers
const colRenderer = ({
  dataKey,
  rowData,
  set,
  t,
  champion,
  isSkinsEnabled,
}) => {
  const locale = getLocale();
  const WinRate = styled("span")`
    color: ${(props) => props.color || "var(--shade0)"};
  `;

  if (dataKey === "champion") {
    return (
      <a href={rowData.href}>
        <Champion>
          <UnitToolTip
            champInfo={champion}
            set={set}
            skinSetting={isSkinsEnabled}
            style={{ zIndex: "3" }}
          >
            <ChampionImage
              champKey={champion?.apiKey}
              cost={champion?.cost}
              size={34}
              set={set}
              skinSetting={isSkinsEnabled}
            />
          </UnitToolTip>
          <div className="type-body2">{rowData.name}</div>
        </Champion>
      </a>
    );
  }

  if (dataKey === "tier") {
    const TierIcon = rowData.tier ? getTierIcon(rowData.tier) : null;
    return TierIcon ? <img src={TierIcon} width="28" height="28" /> : null;
  }

  if (dataKey === "pick_rate" && typeof rowData.pick_rate === "number") {
    const avgPickRate = 0.12;
    const digits = 1;
    const rounding = 1000;
    return (
      <WinRate color={getWinRateColor(rowData.pick_rate, avgPickRate, false)}>
        {getLocaleString(Math.round(rowData.pick_rate * rounding) / rounding, {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
          style: "percent",
        })}
      </WinRate>
    );
  }

  if (
    dataKey === "avg_placement" &&
    typeof rowData.avg_placement === "number"
  ) {
    return (
      <WinRate color={getWinRateColor(rowData.avg_placement, 4, true)}>
        {rowData.avg_placement.toLocaleString(locale, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}
      </WinRate>
    );
  }
  if (
    dataKey === "top_4_percent" &&
    typeof rowData.top_4_percent === "number"
  ) {
    return (
      <div className="type-body2">
        {t("lol:percent", "{{number}}%", {
          number: (rowData.top_4_percent * 100).toLocaleString(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        })}
      </div>
    );
  }
  if (
    dataKey === "top_1_percent" &&
    typeof rowData.top_1_percent === "number"
  ) {
    return (
      <div className="type-body2">
        {getLocaleString(rowData.top_1_percent, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
          style: "percent",
        })}
      </div>
    );
  }

  return null;
};

const Table = () => css`
  .main-col {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
    max-width: 40%;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;

    @container home-table (inline-size <= 400px) {
      width: 20%;
    }
  }
  @container home-table (inline-size <= 400px) {
    .table-header .stat:last-child,
    .table-body .stat:last-child {
      display: none;
    }
  }
`;

const augmentRows = () => css`
  height: 53px !important;
`;

const Champion = styled("div")`
  display: flex;
  gap: var(--sp-4);
  align-items: center;
`;
